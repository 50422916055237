import Player from "@vimeo/player";

class Video {
  constructor(wrapper) {
    this.wrapper = wrapper;
    this.id = this.wrapper.dataset.videoId;
    this.init();
  }
  init() {
    this.player = new Player(this.wrapper, {
      id: this.id,
      responsive: true,
      dnt: true,
      byline: false,
      portrait: false,
      title: false,
    });
    this.player.ready().then(() => {
      this.wrapper.parentNode.style.removeProperty("--aspect-ratio");
    });
  }
}

const players = [];
const playerWrappers = Array.from(
  document.querySelectorAll("[data-video-wrapper]")
);

if (playerWrappers.length) {
  playerWrappers.forEach((wrapper) => {
    const player = new Video(wrapper);
    players.push(player);
  });
}
