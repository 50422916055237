import Alpine from "alpinejs";

import "./tools/reveal";
import "./components/Video";
import "./components/Slideshow";
import * as Nav from "./components/Nav";

document.addEventListener("alpine:init", () => {
  Nav.init();
});

Alpine.start();
