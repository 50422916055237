import Alpine from "alpinejs";

export function init() {
  Alpine.store("nav", {
    open: false,
    toggle() {
      this.open = !this.open;
    },
    close() {
      this.open = false;
    },
  });
}