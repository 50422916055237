import Flickity from "flickity";
require("flickity-imagesloaded");

class Slideshow {
  constructor(container) {
    /* Setup Elements */
    this.container = container;
    this.sliderEl = this.container.querySelector("[data-slideshow-wrapper]");
    this.buttonPrev = this.container.querySelector("[data-slideshow-prev]");
    this.buttonNext = this.container.querySelector("[data-slideshow-next]");
    this.caption = this.container.querySelector("[data-slideshow-caption]");
    /* Setup Slider */
    this.slider = new Flickity(this.sliderEl, {
      wrapAround: this.sliderEl.dataset.slidesCount > 3,
      cellAlign: "left",
      prevNextButtons: false,
      pageDots: false,
      imagesLoaded: true,
    });
    setTimeout(() => {
      this.slider.resize();
      this.container.classList.remove("opacity-0");
    }, 50);
    /* Setup Listeners */
    this.buttonPrev.addEventListener("click", () => {
      this.prev();
    });
    this.buttonNext.addEventListener("click", () => {
      this.next();
    });
    /* Setup Events */
    this.slider.on("select", () => {
      if (!this.slider.options.wrapAround)
        this.updateButtons(
          this.slider.selectedIndex + 1,
          this.slider.slides.length
        );
      this.updateCaption();
    });
  }
  prev() {
    this.slider.previous();
  }
  next() {
    this.slider.next();
  }
  updateButtons(index, count) {
    if (index === 1)
      this.buttonPrev.classList.add("opacity-25", "pointer-events-none");
    else this.buttonPrev.classList.remove("opacity-25", "pointer-events-none");

    if (index === count)
      this.buttonNext.classList.add("opacity-25", "pointer-events-none");
    else this.buttonNext.classList.remove("opacity-25", "pointer-events-none");
  }
  updateCaption() {
    const selectedElement = this.slider.selectedElement;
    if (selectedElement.dataset.caption) {
      this.caption.textContent = selectedElement.dataset.caption;
    } else {
      this.caption.textContent = "";
    }
  }
}

const slideshows = [];
const slideshowContainers = Array.from(
  document.querySelectorAll("[data-slideshow-container]")
);
if (slideshowContainers) {
  slideshowContainers.forEach((container) => {
    slideshows.push(new Slideshow(container));
  });
}
